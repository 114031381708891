.feedBadge {
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight:400;
   font-size: 1rem;
   background-color: transparent;
   border-radius: 50%;
   height: 2rem;
   width: 2rem;
   position: absolute;
   /*transform: translate(-1.8rem, -1.7rem);*/
   transform: translate(-1.3rem, -.8rem);
   z-index: 3;
   border: none;
   color: #ff6bd5;
}



 

/****************RESPONSIVE***************************/
@media screen and (max-width: 320px) {
     
   .feedBadge{
      transform: translate(-1.7rem, -1.4rem);
      font-size: 0.9rem !important;
      font-weight: 400;
  }
  .numberCircle{
   font-size: .8rem !important;
   font-weight: 400;
  }
}

@media screen and (max-width: 695px) {
     
   .feedBadge{
      /*transform: translate(-1.6rem, -1.5rem);*/
      transform: translate(-1.4rem, -.9rem);

      font-size: 0.7 !important;
      font-weight: 400;
  }
  .numberCircle{
   font-size: .9rem;
  }
}