.feedButton {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.feedButton button{
    font-size: 1.2rem;
    padding: 0.5rem;
    padding-left: 1rem;
    margin: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    background-color: transparent;
    color: black; 
    font-family: "Roboto", Helvetica, sans-serif;
    border:none;
   
} 

.feedButton button:hover{
    background-color: transparent;
    color: #800080;
    border:none;
 }
 
 

 .modifyPost button {
     background-color: orange;
     border: none;
 }

 .modifyPost button:hover {
     background-color: orange;
     border: none;
 }



.deletePost button, .sendPost button{
    background-color: transparent;
    border:none;
}

.deletePost button:hover, .sendPost button:hover{
    background-color: transparent;
    color:#800080;
    border: none;
 }

/*****************test*****************/ 
.deletePostIcon button{
    color:#000;
}
.deletePostIcon button:hover{ 
    color:#800080;
   
}
/*******************************/


 
/****************RESPONSIVE************/
@media screen and (max-width:455px) {
   .feedButton button{
      font-size: .9rem !important;
      padding: 0.3rem !important;

      }

}
 
 @media screen and (max-width: 385px) {
     
   .feedButton button{
       font-size: .8rem !important;
       padding: 0.3rem !important;
        
    } }

  @media screen and (max-width: 330px) {
     
   .feedButton button{
        font-size: .7rem !important;
        padding: 0.3rem !important;
       
       }
    }
 
  
  