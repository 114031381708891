.feedButtonComment {
    display: flex;
    justify-content: flex-end;
}

.feedButtonComment button{
    font-size: 1.2rem;
    padding: 0.5rem;
    margin: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-family: Helvetica, sans-serif;
    border: none;
} 


 

.modifyComment button {
     background-color: transparent;
     font-size: .8rem;
     color: black;
 }
 
 .modifyComment button:hover {
       background-color: transparent;
       border: transparent;
       color: #800080;
 }
 


.deleteComment button {
   background-color: transparent;
   color: black;
   font-size: .8rem;
}

.deleteComment button:hover {
    background-color: transparent;
    border: transparent;
    color:#800080;
}

.sendComment button{
     background-color: #800080;
     font-size: .9rem;
     color: white;
}
.sendComment button:hover{
     background-color: #800080;
 }


 /***************RESPONSIVE**********************/
 
 
 @media screen and (max-width: 425px) {
        .feedButtonComment button{
            font-size: 1.1rem;
            padding: 0.4rem;
            margin: 0.5rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .modifyComment button {
            
            font-size: .8rem;
            
         }

         .deleteComment button {
            
            font-size: .8rem;
         }
        }
    
 
 @media screen and (max-width: 375px) {
    .feedButtonComment button{
        font-size: 1rem;
        padding: 0.3rem;
        margin: 0.5rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
    } 
    
    .modifyComment button {
            
        font-size: .8rem;
        
     }

     .deleteComment button {
        
        font-size: .8rem;
     }

}

   
        
        @media screen and (max-width: 320px) {
    .feedButtonComment button{
        font-size: .9rem;
        padding: 0.3rem;
        margin: 0.5rem;
        margin-right: 1rem ;
        margin-bottom: 1rem;
         
    }

    .modifyComment button {
            
        font-size: .8rem;
        
     }

     .deleteComment button {
        
        font-size: .8rem;
     }
    }