.feedComment {
    margin-top: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem; 
    word-break: break-word;
}

.feedComment textarea{
    resize: none;
    width: 145%;
    height: 10rem;
    font-size: 1.1rem;
    border: 1px solid #800080;
    border-radius: 5px;
    padding: .2rem;
    background-color: white;
    font-family: Helvetica, sans-serif;
    overflow: hidden;
}

.feedComment .commentary {
     font-size: 1.1rem;
     color: black;
     text-align: left;
     justify-content: start;
     font-family: "Roboto", sans-serif;
     font-weight: 400;
     font-style: normal;
     margin-left: -5rem;
     margin-top: 1rem;
     overflow: hidden;
   
}

/***************RESPONSIVE**********************/
 @media screen and (max-width:525px) {
    .feedComment .commentary{
         
        font-size: .9rem;
        margin-left: -5rem;
        margin-top: 1rem;
    }

    .feedComment textarea{
        resize: none;
        width: 200% !important;
        height: 10rem;
        font-size: 1.1rem;
        border: 1px solid #800080;
        margin-left: -5rem;
        margin-top: 1rem;
    }

}



@media screen and (max-width:375px) {
    .feedComment .commentary{
         
        
        margin-left: -5rem;
        margin-top: 1rem;
    }

    .feedComment textarea{
        resize: none;
        width: 160%;
        height: 10rem;
        font-size: 1.1rem;
        border: 1px solid #800080;
        margin-left: -5rem;
        margin-top: 1rem;
    }}

@media screen and (max-width:330px) {
    .feedComment .commentary{
         
        
        margin-left: -5rem;
        margin-top: 1rem;
    }

    .feedComment textarea{
        resize: none;
        width: 190% !important;
        height: 10rem;
        font-size: 1.1rem;
        margin-left: -5rem;
        margin-top: 1rem;
    }

}