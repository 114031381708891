.feedImagePost{
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}


.feedImagePost  img {
    box-shadow: 0 0 0 1px #cecece;
    border-radius: 15px;
    max-width: 95%;
    height: 100%;
    object-fit:contain;
    margin-bottom: 1rem;
     
   
}


/****************RESPONSIVE**********************/
@media screen and (max-width: 320px) {
    
    .feedImagePost {
       
      justify-content: center !important;
      align-items: center !important;
     display: flex !important;  
     text-align: center;
    }
     
  }
  
  @media screen and (max-width: 375px) {
    
    .feedImagePost {
       
      justify-content: center;
      align-items: center;
     display: flex;  
    }
    
  }
  

 

 
 