  
 
 html{
  scroll-behavior: smooth;
 }

 *{
   margin:0;
   padding: 0;
   box-sizing: border-box;
  
 }

 ::after, ::before {
  margin:0;
  padding: 0;
 }

 body{
   background-color: #fff !important;
   font-family: 'Roboto', sans-serif; 
   padding-top: 8.5rem;
    }


 section {
  max-width: 100vw;
 }


 p {
  /*font-size: 1.3rem;*/
  color: #fff;
}