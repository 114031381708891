.feedCommentPhotoUser img{
    height: 3rem !important;
    width: 3rem !important;
    margin: 1rem;
    margin-top: -2.6rem;
    margin-right: 2rem;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #cecece;

}


 /***************RESPONSIVE**********************/
 
 

    @media screen and (max-width: 675px) {
        .feedCommentPhotoUser img{
            height: 2.8rem;
            width: 2.8rem;
            margin-top: -2.7rem !important;
             background-color: aqua !important;
        }
      } 
      
      @media screen and (max-width: 320px) {
    .feedCommentPhotoUser img{
        height: 2.6rem;
        width: 2.7rem;
       
    }
    }