.feedSearchBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }

   .container{
    width: 27rem;
    border-bottom: 1px solid #800080 !important;
   }
  
  .searchInner{
    width: 40rem;
    position: absolute;
    top: -24px;
    left: -50%;
    transform: translateX(50%);
 
    

   }
   .searchInner input {
    width: 25rem;
    outline: none;
    padding-left: 0.4rem;
    border: none;
    /*border-bottom: 1px solid #800080 !important;*/
 
    margin-top: 25px;
    font-size: 0.9rem;
    font-family: Helvetica, sans-serif;
    /*background-color: white;*/
   background-color: transparent;

   
  }
  
   
  
  .dropdown{
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    margin-top: 25px;
    margin-left: -160%;
     
   }
   
  .dropdownRow {
    cursor: pointer;
    text-align: start !important;
    margin: 2px 0;
    color: black;
    font-family: "Roboto", sans-serif;
   
    padding: .3rem;
    z-index: 10000;
  }
  
  .searchInner button{
    cursor: pointer;
    /*background-color: #800080 !important;*/
    background-color: transparent;
    border: transparent;
   /* border-bottom: 1px solid #800080 !important;*/
 
    font-size: small;
    position:absolute;
    top: 20px;
     
    

  }
  
  .searchInner button:hover{
    color: #800080;
    /*font-size: 1rem;*/
    border: 0px solid transparent !important;
    transition: ease-in-out .5s;
    top:20px;
     
  }
   

  /********************RESPONSIVE*********************/
  @media screen and (max-width: 1200px) { 
    .searchInner{
      width: 15rem;
       top: 25px;
      left: -46%;
      transform: translateX(130%);
     
     }
     .searchInner button {
      left: 160%
     }

     .container{
      width: 26rem;
     }
  }
  
  @media screen and (max-width: 475px) {
    .searchInner{
      width: 10rem;
      top: 25px;
      left: 7%;
      transform: translateX(10%);

     
     }
     .searchInner input{
      width: 17rem;
     }
      .container{
        width: 18rem;
      }
    }

    @media screen and (max-width: 465px) {
      

      .container{
        width: 20rem;
      }
    
    }



    @media screen and (max-width: 435px) {
      .searchInner{ 
        left: 10% !important;
      }

      .container{
        width: 18rem;
      }
    
    }


    @media screen and (max-width: 430px) {
      .searchInner{ 
        left: 10% !important;
      }

      .container{
        width: 18rem;
      }
    
    }

    @media screen and (max-width: 415px) {
      .searchInner{ 
        left: 10% !important;
      }
    
    }

    @media screen and (max-width: 390px) {
      .searchInner{
        left: 9% !important;
       }


    }

   @media screen and (max-width: 375px) {
    .searchInner{
      width: 10rem;
      top: 25px;
      left: 30% !important;
      transform: translateX(-70%);
      }


    .searchInner input {
      width: 16rem;
      
  
    }
  .container{
    width: 18rem;
  }
  } 

   @media screen and (max-width: 330px) {
    .searchInner{
      width: 10rem;
      top: 25px;
      
      
     }
    .searchInner input {
      width: 17rem;
      
      /*left: -120%;*/
    } 
     .container{
      width: 18rem;
     }
  } 
   