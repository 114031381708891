.wrapper{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    max-width: 30rem;

}

.auth {
    border: 2px solid transparent;
    background-color: rgb(246, 247, 247);
    text-align: center;
    margin: 3rem auto;
    padding: 1rem;
    width: 50%;
    max-width: 30rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 }

.auth h1 {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 1.4rem;
    margin-top: .3rem;
    color: rgb(14, 2, 2);
    font-family: "Roboto",  Helvetica, sans-serif;
}

 
.actions{
    color: #000000;
}

.actions button{
  background-color: #800080;
  color: #fff;
  font-size: 1.3rem;
  font-family: "Roboto", Helvetica, sans-serif;
  border: none;
  padding: 0.6rem 2rem;
}

.actions button:hover{
     background-color: #800080;
     border: none;
 }

.auth ul {
   margin-top: 1rem;
}

.auth li {
    list-style: none;
}

.auth li p {
   font-size: 0.9rem !important;
   color: #000000;
   font-weight: 400;
   color:#800080;
}


.auth .checkbox  {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}


.auth .checkbox input {
   margin-left: 1rem;
   height: .8rem;
   width: .8rem;
   
   
} 

.auth .checkbox label {
    color: rgb(22, 22, 2); 

}



.control {
     margin-bottom: 1rem;
      
}

 .control .emailLabel{
    display: none;
 } 


 .control .passwordLabel{
    display: none;
 }

 .control .confirmationPasswordLabel{
    display: none;
 }

.control label {
     
    font-size: .9rem;
    display: block;
    color: rgb(36, 34, 34);
    border: #100202;
    font-weight:500;
    margin-bottom: 0.2rem;
    font-family: "Roboto",  Helvetica, sans-serif ;
}


.control input {
    font: inherit;
    font-size: 1rem;
    background-color: rgba(246, 248, 246, 0.798);
    max-width: 70%;
    padding: 0.4rem;
    border: 1px solid transparent;
    border-bottom: 1px solid #800080 !important;
    /*border-radius: 5px !important;*/
    outline: none;
      
}

.confirmationPasswordInput{
    margin-top: 1rem;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0rem;
}

.actions p {
    font-family: inherit;
    font-size: 1.3rem;
    color: rgb(14, 14, 14);
    padding-top: 1rem;
    
}

.actions p:nth-child(2) {
    cursor: pointer;
    font-size: 1.3rem;

}

.actions p:nth-child(2):hover {
     color:#800080;
     font-size: 1.3rem;
}


/****************RESPONSIVE************************/
@media screen and (max-width: 700px) {
     
    .auth{
         width: 85%;
          }

          .auth h1 {
            font-size: 1.2rem;
            text-align: center;
            
        }     

        .control label {
            font-size: .9rem;
             margin-top: 1rem;
        }

        .control input {
             
            font-size: .8rem;
            
            max-width: 70%;
            padding: 0.2rem;
            
            
        }

        .auth .checkbox input {
            margin-top: 1rem;
            height: .8rem;
            width: .9rem;
            margin-left: .7rem;
            
         } 

         .actions button{
            background-color: #800080;
            color: #fff;
            font-size: 1rem;
            
           }


           .actions p:nth-child(2) {
            cursor: pointer;
            font-size: 1rem;
        
        }
        
        .actions p:nth-child(2):hover {
             color:#800080;
             font-size: 1rem;
        }
  }
  
  


  @media screen and (max-width: 375px) {
     
    .auth{
         width: 85%;
          }

          .auth h1 {
            font-size: 1.1rem;
            text-align: center;
            
        }     

        .control label {
            font-size: .9rem;
             margin-top: 1rem;
        }

        .control input {
             
            font-size: .8rem;
            
            max-width: 70%;
            padding: 0.2rem;
            
            
        }

        .auth .checkbox input {
            margin-top: 1rem;
            height: .8rem;
            width: .9rem;
            margin-left: .7rem;
            
         } 

         .actions button{
             background-color: #800080;
             color: #fff;
             font-size: 1rem;
            
           }


           .actions p:nth-child(2) {
            cursor: pointer;
            font-size: 1rem;
        
        }
        
        .actions p:nth-child(2):hover {
             color:#800080;
             font-size: 1rem;
        }

        .auth li p {
            font-size: 0.7rem !important;
            font-weight: 400;
         }
         
  }

  @media screen and (max-width: 350px) {
     
    .auth{
         width: 85%;
          }

          .auth h1 {
            font-size: 1.1rem;
            text-align: center;
            
        }     

        .control label {
            font-size: 0.7rem;
             margin-top: 1rem;
        }

        .control input {
             
            font-size: .7rem;
            
            max-width: 70%;
            padding: 0.2rem;
            
            
        }

        .auth .checkbox input {
            margin-top: 1rem;
            height: .8rem;
            width: .9rem;
            margin-left: .7rem;
            
         } 

         .actions button{
            background-color: #800080;
            color: #fff;
            font-size: 1rem;
            
           }

           .actions p:nth-child(2) {
            cursor: pointer;
            font-size: 1rem;
        
        }
        
        .actions p:nth-child(2):hover {
             color: #800080;
             font-size: 1rem;
        }
  }

   
 