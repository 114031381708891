
.backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.753);
    top:0;
    left:0;
    width: 100%;
    height: 1000vh;
    z-index: 100000;
}


.modal {
    position: fixed;
    top: 40vh;
    left: 20%;
    width: 60%;
    z-index: 1111111111;
    overflow: hidden;

}


.header {
    background-color: #800080;
    padding: 2rem;
}


.header h2 {
    font-size: 2rem;
    color: white;
}

.content {
    padding: 2rem;
 }

.content p {
    color: #000; 
    font-size: 1.6rem;
    font-weight: 900;
}

.actions {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}


.modalConfirmation button{
    margin: 1rem;
    padding: 0.6rem 2rem;
}

.modalConfirmation button:nth-child(1){
    background-color: #800080;
    color: white;
    font-size: 1.7rem;
    border:none;
}

.modalConfirmation button:nth-child(1):hover{
    background-color:#800080 ;
}

.modalConfirmation button:nth-child(2){
    background-color: white ;
    border: 1px solid #800080;
    color: #800080;
    font-size: 1.7rem;
}


.modalConfirmation button:nth-child(2):hover{
    color: #000 ;
}

/*********************************************************/
/***@media screen and (max-width:350px)**/
@media only screen and (min-device-width : 320px) and (max-device-width : 1070px) {

     
    .modal{
       width: 90% ;
       left: 6%;
       top: 90px;
        
       
    }

    .header h2 {
        font-size: 1rem !important;
        color: white !important;
} 

.content p{
   font-size: 1rem;
}

.modalConfirmation button:nth-child(1){
    font-size: 1rem;
}
.modalConfirmation button:nth-child(2){
   font-size: 1rem;
}

};


 


 


 
 



 
 