.container {

    display: flex;
 }


.user {
    margin: 0.5rem;
    border-radius: 30px;
    background-color: rgb(246, 247, 247);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
    overflow: hidden;
    width: 30rem;
    margin: 1rem auto;
    padding: 1.9rem;
}

.user h1{
    font: inherit;
    font-size: 2rem;
    font-weight: bold !important;
    font-family: "Roboto", Helvetica, sans-serif;
 }

 

.user p {
    color: black;
    font-weight:800;
    margin-top: 0.8rem;
    font-size: 1rem;
    font-family: "Roboto", Helvetica, sans-serif;

}

 

.user .bio{
    font-weight: 400 !important;
}

.user img {
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    margin: 1.5rem;
    object-fit: cover;
    box-shadow: 0 0 0 1px #cecece;
}

 

  /***************RESPONSIVE**********************/
  @media screen and (max-width:435px) {
    .user {
        
        width: 320px !important;
        margin: 1rem auto;
        padding: 1.9rem;
    }
    .user h1{
        font: inherit;
        font-size: 1.3rem;
        font-weight: bolder;
        
    }

    .user p {
        color: black;
        font-weight:800;
        margin-top: 0.3rem;
        font-size: 1.1rem;
       font-weight:700;
    
    }
       
    }


  

    @media screen and (max-width: 375px) {
        .user {
            
            width: 320px !important;
            margin: 1rem auto;
            padding: 1.9rem;
        }
        .user h1{
            font: inherit;
            font-size: 1.2rem;
            
        }
    
        .user p {
            color: black;
            font-weight:800;
            margin-top: 0.3rem;
            font-size: 1rem;
            
        
        }
           
        }

        @media screen and (max-width: 320px) {
            .user {
                
                width: 310px !important;
                margin: 1rem auto;
                padding: 1.9rem;
            }
            .user h1{
                font: inherit;
                font-size: 1.1rem;
                font-weight: bolder;
                font-family: Helvetica, sans-serif;
            }
        
            .user p {
                color: black;
                font-weight:800;
                margin-top: 0.8rem;
                font-size: .9rem;
                font-family: Helvetica, sans-serif;
            
            }
               
            }
        
        
        
    
    

 