
.button {
    font: inherit;
    /*font-size: 2rem;*/
    /*background-color: rgb(123, 192, 123);*/
   /* padding: 0.6rem 2rem;*/
    border-radius: 5px;
    /*border: 1px solid white;*/
    cursor: pointer;  
}

.button:hover {
    /*background-color: rgb(104, 157, 104);*/
    /*border-color: rgb(104, 157, 104);*/
    /*color: white;*/
    border: none;
}
