.feedIdentifierCreatorComment p{
    padding: 0.1rem;
    font-size: .8rem;
    margin-left: 4.5rem !important;
    color: black;
    margin-left: 5.5rem;
    font-family: "Roboto", sans-serif;
    font-style: normal;
}


.feedIdentifierCreatorComment p:nth-child(1) {
    margin-top: .8rem;
    font-weight: bold;
    font-size: 1rem;
}

.feedIdentifierCreatorComment p:nth-child(2) {
    font-size: .7rem !important;
    font-weight: 300;
}



 /***************RESPONSIVE**********************/
 
 

 @media screen and (max-width: 675px) {
    .feedIdentifierCreatorCommentPrenom{
        margin-left: 4.5rem !important;
        font-size: 1rem !important;
        margin-top: .9rem !important;
    }

    .feedIdentifierCreatorCommentDate{
        margin-left: 4.5rem !important; 
    }
  }
  
  @media screen and (max-width: 360px) {
    .feedIdentifierCreatorCommentPrenom{
        margin-left: 4.5rem !important;
        font-size: .9rem !important;
        margin-top: .9rem !important;
    }

    .feedIdentifierCreatorCommentDate{
        margin-left: 4.5rem !important; 
    }

}