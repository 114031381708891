.feedIdentifierCreator p{
    padding: 0.1rem !important;
    font-size: 1rem;
    margin-left: 5.5rem !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    
}


.feedIdentifierCreator p:nth-child(1) {
    margin-top: 1rem;
    font-weight: bold;
    font-size: 1rem !important;
}

.feedIdentifierCreator p:nth-child(2) {
     font-weight: 300;
    font-size: .8rem !important;
    
}


/*****************RESPONSIVE****************************/

@media screen and (max-width: 320px) {

    .feedIdentifierCreator p:nth-child(1) {
         margin-top: 0.8rem;
         font-weight: bold;
         font-size: 1rem !important;
    }
     
    .feedIdentifierCreator p:nth-child(2) {
     
        font-size: .6rem !important;
        
    }
    
 }

 @media screen and (max-width: 385px) {

    .feedIdentifierCreator p:nth-child(1) {
        margin-top: 0.8rem;
        margin-left: 79px !important;
        font-weight: bold;
        font-size: 1rem !important;
    }
     
    .feedIdentifierCreator p:nth-child(2) {
     
        font-size: .6rem !important;
        margin-left: 79px !important;

    }
    
 }
