  .feedNewPost {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

   
  }


  .feedNewPost p {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  } 

  .feedNewPost .card {
     background-color: rgb(246, 247, 247);
     margin: 1rem;
     width: 666px;
     box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }


 .feedNewPost form {
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
  }


  .feedNewPost  label {
    color: black;
    font-size: 1.3rem;
    font-weight:600;
    margin: 1rem;
    font-family: Helvetica, sans-serif;
  }


  .feedNewPost button {
    background-color: #800080;
    margin: 1rem;
    font-size: 1.1rem;
    color: white;
    font-family: Helvetica, sans-serif;
    border: none;
    padding: 0.6rem 2rem;
  }

  .feedNewPost button:hover{
    background-color: #800080;
    
  }


  .feedNewPost form textarea {
    margin: 0 1rem;
    resize: none;
    width: 95%;
    height: 12rem;
    font-size: 1.1rem;
    background-color: white;
    /*border: 1px solid #ff6bd5;*/
    border:1px solid #800080;
    border-radius: 8px;
    padding: 0.5rem;
    font-family: Helvetica, sans-serif;
  }


   .feedNewPost #messageEmpty {
    color:#800080;
    border-radius: 10px;
    padding: 0.5rem 0.5rem;
    font-size: .9rem !important;
    margin-bottom: 2rem !important;
  }

  .feedNewPost #red{
    background-color:  rgb(205, 59, 59);
  }


  .feedNewPost .buttonsMedia {
    display: flex;
  }



  /**********RESPONSIVE**********/
  
  @media screen and (max-width:450px) {
    .feedNewPost .card{
       width: 100px;
       max-width: 315px !important;
         
    }
    .feedNewPost form textarea {
      height: 7rem;
      font-size: .9rem;
    }

    .feedNewPost button {
      margin: 1rem;
      font-size: 1rem;
      color: white;
      font-family: Helvetica, sans-serif;
     }

  }

   



  @media screen and (max-width:750px) {
    .feedNewPost .card{
        max-width: 400px;
    }
  }
  
  @media screen and (max-width:1024px) {
    .feedNewPost .card{
      width: 400px;
      max-width: 400px;
   }

   .feedNewPost form textarea {
    height: 10rem;
  }
  }