.feedLike {

    width: 100%;
}


.feedLike .heart {
    margin-left: 2rem !important;
    color: #ff6bd5 !important;
    cursor: pointer;
      
}


/*********************RESPONSIVE****************/

@media screen and (max-width:1300px) {
    
    .feedLike .heart {
        margin-left: 1rem;
        margin-bottom: 10px;

          
    } }


@media screen and (max-width:1024px) {
    .feedLike .heart {
       
      margin-bottom: 10px;
    
   }
  }
  
@media screen and (max-width:768px) {
    .feedLike .heart {
       
      margin-bottom: 10px;
    
   }
  }
  
  @media screen and (max-width: 375px) {
     
    .feedLike .heart {
        margin-left: 1rem;
        
          
    }}

    
  @media screen and (max-width: 320px) {
     
     
    .feedLike .heart {
       
       margin-left: -1px;
     
    } }
  




  
  
  
  
