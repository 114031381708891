 .fixed{
    position: fixed;
    width: 100%;
    z-index: 55;
    top:0 !important;
    padding-top: 0 !important;
    /*background-color: rgb(248, 249, 248);*/
    background-color: white;
   

 }

.header {
    /*background-color: rgb(248, 249, 248);*/
    border-bottom: 1px solid #800080;
    min-width: 100%;
    height: 80px;
   }

.header h1{
    font-family: Tahoma, "Roboto", Helvetica, sans-serif !important;
    font-size: 1.7rem;
    margin-left: 25rem;
    margin-top: 2rem;
    /*color:#800080 ;*/
    color: black;
    font-weight:300;
    
}

 
 

.header ul {
    font-size: 1.1rem;
    font-weight: 600;
    color: hsl(0, 0%, 0%);
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-top: -10px;
 }

.header .searchBar{
    position: absolute;
    bottom: 40px;
    top: 6px;
    width: 40rem;
    right: 50%;
    transform: translateX(65%);
      background: transparent;
}

.header li {
    padding: 0 1rem;
    margin-top: 1rem !important;
    cursor: pointer;
   
    
}

.page:hover{
     color:#800080;
 }
.disconnect {
    color: black;
     cursor: pointer;

}

.disconnect:hover {
     color: #800080;
 }


 a {
    color: rgb(24, 23, 23);
    text-decoration: none;
}


a:hover {
    color:#800080;
}


.active {
     color:#800080;
}

.active:hover{
     color: #800080;
}


/*********************RESPONSIVE*****************************/
  
@media screen and (max-width:1600px) {
    .header {
        
        height: 115px;
        display: flex;
        flex-direction: column;
        
   }
   
    .header h1{
        
       font-size: 1.4rem;
       margin-left:10%;
       margin-top: 1px;
         
    }
    .header ul{
        font-size: 1rem;
        margin-top: 30px;
   }

   .header li {
    padding: 0 1rem;
    margin-top: 1rem !important;
    cursor: pointer;
     
} 
}


 @media screen and (max-width:1500px) {
      .header h1{
        margin-left: 210px;
        font-size: 1rem;
      }

      .header ul{
        font-size: 1rem;
        margin-top: 30px;
       
   }

  }

 
  @media screen and (max-width:1200px) {
    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 115px;    
        
   }

   .header h1{
     margin-left: -0.5%;
     font-size: 1.3rem;
     
    
   }
   
     
    .header ul{
        font-size: 1rem;
        margin-top: 36px;
       
   }

   .header li {
    padding: 0 1rem;
    margin-top: 1rem !important;
    cursor: pointer;
     
} 
}
 
@media screen and (max-width:790px) {
    .header {
        
        height: 115px;
        display: flex;
        flex-direction: column;
        
   }
   
    .header h1{
        
       font-size: 1.4rem;
       margin-left: -6%;
       margin-top: 2px;
         
    }
    .header ul{
        font-size: 1rem;
        margin-top: 34px;
   }

   .header li {
    padding: 0 1rem;
    margin-top: 1rem !important;
    cursor: pointer;
     
} 
}
 
@media screen and (max-width: 475px) {
     .header h1 {
        margin-left: 30%;
        transform: translateX(-50%);
        
     }

}


@media screen and (max-width: 465px) {
    .header h1 {
       margin-left: 20%;
       transform: translateX(-50%);
       
    }

}
 
@media screen and (max-width:450px) {
    .header{
        height: 115px !important;
        display: flex !important;
        flex-direction: column !important;
         
   }
   
   .header h1{
    margin-left: 30%;
  
   }
 

    .header ul{
        font-size: .8rem;
        margin-top: 35px;
   }

   .header li {
    padding: 0 1rem;
    margin-top: 1rem !important;
    cursor: pointer;
     
  } 
}
 


   @media screen and (max-width: 420px) {
     .header {
        width: 375px;
        height: 135px;
        display: flex;
        flex-direction: column;

   }
   
    .header h1{
       font-size: 1.3rem;
       margin-left: 30%;
       margin-top: .7rem;
         
    }
    .header ul{
        font-size: 0.8rem;
        margin-top: 27px;
   }

   .header li {
    padding: 0 1rem;
    margin-top: 1rem  ;
    cursor: pointer;
     
} 
   
   } 


   


@media screen and (max-width: 340px) {
    .header {
        width: 320px;
        height: 135px;
        display: flex;
        flex-direction: column;

   }
   
    .header h1{
        font-family: Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1.3rem;
        margin-left: 30%;
        margin-top: .1rem !important;
        
       
         
    }
    .header ul{
        font-size: .7rem !important;
        margin-top: 35px;
   }

   .header li {
    padding: 0 1rem;
    margin-top: 1rem !important;
    cursor: pointer;
     
} 
    
   } 
    
  
  
   
  
  
  