.feedPhotoUser img{
    height: 4rem;
    width: 4rem;
    margin: 1rem;
    margin-right: 2rem;
    margin-top: -3rem;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #cecece;
}


/*****************RESPONSIVE****************************/

@media screen and (max-width: 320px) {

    .feedPhotoUser img{
        height: 3.7rem;
        width: 3.7rem;
        margin-left: 0.7rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 375px) {

    .feedPhotoUser img{
        height: 3.8rem;
        width: 3.8rem;
        margin-left: 0.7rem;
        margin-top: -2.6rem;
        
    }
    
    
 }
 