.feedImageUrl{
    display: flex;
}

.feedImageUrl button{
     font-size: 1rem;
     padding: 0.5rem;
     margin: 0.5rem;
     margin-right: 1rem;
     margin-left: 1rem;
     font-family:  Helvetica, sans-serif !important;
     
} 


.feedImageUrl button:nth-child(1) {
    background-color: white;
    border: 1px solid #800080;
    color: black;
    font-weight: 700;
    font-size: 0.9rem;

}


.feedImageUrl button:hover {
     background-color: white;
     color:#800080 ;

}


.feedImageUrl input {
    width: 25rem;
    height: 2.6rem;
    border: none;
    border-bottom: 1px solid #800080 !important;
    font-size: 1rem;
    margin-top: 0.3rem;
    padding-left: 1rem;
    background-color: white;
    outline: none;
    overflow: hidden;
    
}


/***************RESPONSIVE*********************/
@media screen and (max-width:550px) {
    
    .feedImageUrl button{
        font-size: .8rem !important;
        width: 4rem;
        justify-content: center;
        align-items: center;
        padding: .3rem;  
        
   }

   .feedImageUrl input {
    width: 13rem !important;
    height: 2rem;
    padding-left: 1rem;
    font-size: .8rem !important;
    margin-top: 0.5rem !important;
    margin-left: -0.9rem;
    
}  
     
  }


@media screen and (max-width: 320px) {
    .feedImageUrl button{
        font-size: .8rem !important;
        width: 4rem;
        justify-content: center;
        align-items: center;
        padding: .1rem;
        
   }

   .feedImageUrl input {
    width: 8rem;
    height: 1.6rem !important;
    padding-left: 1rem;
    font-size: .8rem;
    margin-top: 0.5rem;
    margin-left: -0.9rem;
    
}

     
   } 

   @media screen and (max-width: 385px) {
    .feedImageUrl button{
        font-size: .8rem !important;
        width: 4rem;
        justify-content: center;
        align-items: center;
        padding: .3rem;
        
   }

   .feedImageUrl input {
    width: 13rem !important;
    height: 1.7rem !important;
    padding-left: 1rem;
    font-size: .8rem;
    margin-top: 0.5rem;
    margin-left: -0.9rem;
    
}

     
   } 


   
  
  @media screen and (max-width:768px) {
    .feedImageUrl input {
        width: 12rem;
        height: 1.7rem !important;
        padding-left: 1rem ;
        font-size: .8rem;
        margin-top: 0.5rem;
        margin-left: -0.9rem;
        
    }
  }
  
  @media screen and (max-width:1024px) {
    .feedImageUrl input {
        width: 16rem;
        height: 2rem;
        padding-left: 1rem;
        font-size: .8rem;
        margin-top: 0.5rem;
        margin-left: -0.9rem;
          
        
    }
  }