 .feedDisplayComment{
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   width: 33rem;
   margin-left: 10rem;
   margin-bottom: 0.8rem;
 }

  
 
 .feedDisplayComment p {
    font-size: 1.5rem;
 }


.feedDisplayComment .photoComment {  
    display: flex;
 }


 .feedDisplayComment .card {
   background-color: rgb(246, 247, 247);
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   width: 100%;
   margin: 1rem 0;
   overflow: hidden;
  }


  /***************RESPONSIVE**********************/
  @media(max-width:885px) {
    .feedDisplayComment{
        margin-left: 1rem;
    }
  }
  
  @media(max-width:585px) {
    .feedDisplayComment{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 460px;
      margin-left: 3rem;
      margin-bottom: 0.8rem;
      
    }
 }


      @media(max-width:445px) {
        .feedDisplayComment{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 360px !important;
          margin-left: 1rem !important;
          margin-bottom: 0.8rem !important;
          
        }
     }


     @media screen and (max-width:400px) { 
       .feedDisplayComment {
        width: 350px !important;
       }

     }
      
    
     
     @media screen and (max-width:375px) {
      .feedDisplayComment{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 300px !important;
        margin-left: 2rem;
        margin-bottom: 0.8rem;
        
      }}
  
  
      @media screen and (max-width:320px) {
    .feedDisplayComment{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 280px !important;
      margin-left: 1rem !important;
      margin-bottom: 0.8rem;
    }
    }

   
       
     