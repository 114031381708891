.feedBadgeForLikes {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight:400;
    font-size: 1rem;
    background-color: transparent;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    position: absolute;
    transform: translate(.7rem, -.009rem);
    z-index: 2;
    border : 1px solid transparent;
    color:grey;
 }
 
 
 
 
 
 /****************RESPONSIVE***************************/
 
 
 @media screen and (max-width: 320px) {
      
    .feedBadgeForLikes{
       transform: translate(.7rem, -.4rem);
       font-size: 0.9;
       font-weight: 500;
   }
 }
 
 @media screen and (max-width: 595px) {
      
    .feedBadgeForLikes{
       /*transform: translate(.8rem, -.4rem);*/
       transform: translate(.7rem, -.8rem) ;
       font-size: 0.9;
       font-weight: 400;
   }
 }


 @media screen and (max-width: 795px) {
      
   .feedBadgeForLikes{
      /*transform: translate(.8rem, -.4rem);*/
      transform: translate(.7rem, -.5.5rem) ;
      font-size: 0.9;
      font-weight: 400;
  }
}



 @media screen and (max-width: 1195px) {
      
   .feedBadgeForLikes{
      /*transform: translate(.8rem, -.4rem);*/
      transform: translate(.7rem, -.0005rem);
      font-size: 0.9;
      font-weight: 400;
   }
}


 @media screen and (max-width: 1595px) {
      
   .feedBadgeForLikes{
      /*transform: translate(.8rem, -.4rem);*/
      transform: translate(.7rem, -.5rem) ;
      font-size: 0.9;
      font-weight: 400;
  }
}
