
.backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.753);
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index: 10000000;
}


.modal {
    position: fixed;
    top: 40vh;
    left: 10%;
    width: 80%;
    z-index: 1111111111;
    overflow: hidden;

}


.header {
    background-color: #800080;
    padding: 2rem;
    
}


.header h2 {
    font-size: 2rem;
    color: white;
    font-family: Helvetica, sans-serif;
}

.content {
    padding: 2rem;
    font-size: 3rem;
}

.content p {
    color: #000; 
    font-size: 1.6rem;
    font-weight: 900;
    font-family: Helvetica, sans-serif;
}

.actions {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    background-color: white;
}

.actions button {
    background-color: #800080;
    border:none;
    color: white;
    font-size: 1.7rem;
    font-family: Helvetica, sans-serif;
    padding: 0.6rem 2rem;
}

.actions button:hover{
    background-color: #800080;
 }



/***************RESPONSIVE*********************/
@media screen and (max-width: 900px) { 
    .header h2 {
        font-size: 1.3rem;
   
   }

   .content p {
    color: #000; 
    font-size: 1.1rem;
    font-weight: 900;
    font-family: Helvetica, sans-serif; 
}

.actions button {
    color: white;
    font-size: 1.1rem;
}

}


   @media screen and (max-width: 600px) { 
    .header h2 {
        font-size: 1.2rem;
   
   } 
   .content p {
    color: #000; 
    font-size: 1rem;
    font-weight: 900;
    font-family: Helvetica, sans-serif; 
}

.actions button {
    color: white;
    font-size: 1rem;
}
}
 

@media screen and (max-width: 375px) {
    .header h2 {
        font-size: 1.1rem;
   
   } 
   .content p {
       color: #000; 
       font-size: .9rem;
       font-weight: 900;
       font-family: Helvetica, sans-serif; 
   }
   
   .actions button {
       color: white;
       font-size: 1.1rem;
   }
     
  }
  
@media screen and (max-width: 320px) {
      .header h2 {
         font-size: 1rem;
    
    } 
    .content p {
        color: #000; 
        font-size: .8rem;
        font-weight: 900;
        font-family: Helvetica, sans-serif; 
    }
    
    .actions button {
         color: white;
         font-size: 1rem;
    }
  }
  
 










