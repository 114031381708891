.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.user {
    margin: 0.5rem;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
    overflow: hidden;
    max-width: 50rem;
    margin: 1rem;
    padding: 1.5rem;
    background-color: rgb(246, 247, 247);
    font-family: "Roboto", sans-serif;
    font-style: normal;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.user h2{
   font-size: 1.2rem;
   padding-bottom: 0.8rem;
   font-weight: 600;
   font-family: "Roboto", sans-serif;
}

.user h1{
    font: inherit;
    font-size: 1.7rem;
    font-weight: bold;
    color: black;
    font-family: "Roboto";

}

.user p {
   color: black;
   margin-bottom: 0.5rem;
   font-size: 1.1rem;
   margin-top: -7px;
   font-weight: bold;
   padding-bottom: .9rem; 
}

 .user .bio{
    font-weight: 400;
 }

.user .bold {
    color:black;
    font-weight: bold;
    margin-top: 0.4rem;
    font-size: 1.1rem;
    text-transform: capitalize;
}

.user img {
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    margin: 1.5rem;
    object-fit: cover;
    box-shadow: 0 0 0 1px #cecece;
}

.user button {
    font-size: 1.1rem;
    margin: 1.5rem 0.5rem;
    background-color: #800080;
    color: white;
    font-family: Helvetica, sans-serif;
    border:none;
    padding: 0.6rem 2rem;
}

.user button:hover {
    background-color: #800080;
 }

.user button:nth-child(2) {
    background-color: white;
    color: black;
    border: 1px solid #800080;
    font-size: 1.1rem;
}

.user button:nth-child(2):hover {
    color:#800080;
     
}


span{
    font-family: 'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    border-radius: 5px;
    padding: 3px;
    background-color: #800080;
    color:white;
    cursor: pointer;  
    font-size: 1rem;
}

 

.inputFile {
    display: none;
   
}


input {
    background-color: white;
    width: 70%;
    font-size: 1rem;
    font-weight: 100;
    border: 1px solid #ff6bd5;
    border: 1px solid #800080;
    padding: .2rem;
}

 

.user textarea{
    resize: none;
    height: 10rem;
    width: 95%;
    background-color: white;
    font-size: 1.3rem;
    border-radius: 5px;
    border: 1px solid #ff6bd5;
    border: 1px solid #800080;
    padding: .2rem;
    
}


.group-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.form-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.form-control label {
    font-size: 1rem;
    font-weight: 600;
}

.form-control input {
    width: 80%;
    border-radius: 5px;
    margin-top: 0.3rem;
    font-weight: 100;
    padding: .2rem;
    font-size: 1rem;
    
}


.form-control input:focus, textarea:focus {
    background-color: white;
    border: solid black 1px;
    outline: none;
}


 


.invalid input:focus {
     background-color: white;

}

.error-text {
    color: white !important;
    font-style: oblique;
    font-size: .8rem;
    background-color: #800080;  
    border-radius: 5px;
    margin-top: 0.3rem;
    text-transform: lowercase !important;
    font-weight: 400 !important;
    padding: .2rem;
    padding-bottom: 0.2rem !important;
 }

/**************RESPONSIVE*******************/
@media(max-width:695px) {
    .user {
        max-width: 330px;
     }

    .user h1{
        font-size: 1.3rem;
        padding-bottom: 0.8rem;
     }
     
     .user h2{
         
         font-size: 1rem;
         
     }
     .user button {
        font-size: 1.2rem;
        margin: .3rem 0.5rem;
        /*background-color: #ff6bd5	 ;*/
        background-color: #800080;
        color: white;
    
    }

     .user button:nth-child(1) {
        background-color:#800080;
        color: white;
         border: 1px solid ;
        font-size: .8rem;
        align-items: center;
        justify-content: center; 
        width: 210px;
         
    }

    .user button:nth-child(2) {
        background-color: white;
        /*color: black;*/
        /*border: 1px solid #ff6bd5	;*/
        border: 1px solid #800080;
        font-size: .8rem;
        align-items: center;
        justify-content: center;

        
    }
       
}



    @media screen and (max-width:375px) {
         .user {
            max-width: 320px;
         }

        .user h1{
            font-size: 1.2rem;
            padding-bottom: 0.8rem;
         }
         
         .user h2{
             
             font-size: 1rem;
             
         }
         .user button {
            font-size: 1.1rem;
            margin: .3rem 0.5rem;
            /*background-color: #ff6bd5	 ;*/
            background-color: #800080;
            color: white;
        
        }
    
        .user button:nth-child(2) {
            background-color: white;
            color: black;
           /* border: 1px solid #ff6bd5	;*/
           border: 1px solid #800080;
            font-size: .9rem;
            align-items: center;
            justify-content: center;
    
            
        }
           
        }

        
        @media screen and (max-width:320px) {
            .user h1{
                font-size: 1.1rem;
                padding-bottom: 0.8rem;
             }
             
             .user h2{
                 
                 font-size: 1rem;
                 
             }
             .user button {
                font-size: 1rem;
                margin: .3rem 0.5rem;
                 color: white;
            
            }
        
            .user button:nth-child(2) {
                background-color: white;
                color: black;
                /*border: 1px solid #ff6bd5	;*/
                font-size: .8rem;
                align-items: center;
                justify-content: center;
        
                
            }
               
            }
