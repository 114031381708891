 .feedDisplayPost {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

 .feedDisplayPost h2{
     color: black; 
     font-size: 1.1rem;  
     font-family: Helvetica, sans-serif; 
     font-weight: 700;
     margin-top: 2rem;
     
 }

 .container {
  /**c'est là où il faut mettre les options de navigation sur les côtes
  /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;*/
    height: inherit;
    }

   .card {
    background-color: rgb(246, 247, 247);
    width: 100%;
    margin: 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 43rem;
    max-width: 43rem;
    max-height: 145rem;
    overflow: hidden;
    
 }

 .card p {
    font-size: 2rem !important;
    padding: 1rem;
    margin-left: 1rem;
    color:black;
     
 }

  

 .photoMessage {

   display: flex; 
   
   
 }

   

 .likeAndButton {

   display: flex;
   justify-content: flex-end;
   align-items: center;
   
 }
  
 /*************RESPONSIVE********/

 @media only screen and (min-device-width : 320px) and (max-device-width :780px) {
 .card {
  max-width: 95%;
   min-width: 95%;
  
}
.card p {
  font-size: 1.1rem !important;
  margin-left: -5rem !important;
  color:black;
}

  };

  @media only screen and (min-device-width:700px) and (max-device-width:1080px) {
    .card {
     max-width: 75%;
      min-width: 75%;
      
   }
     }
   
 
 



