.feedIdentifierCreator p{
    padding: 0.1rem !important;
    font-size: 1.3rem !important;
    margin-left: 5.5rem !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    
}


.feedIdentifierCreator p {
    margin-top: 1rem;
    font-weight: bold;
     
    
}

 


/*****************RESPONSIVE****************************/

@media screen and (max-width: 320px) {

    .feedIdentifierCreator p:nth-child(1) {
        margin-top: 0.8rem;
        font-weight: bold;
        font-size: 1rem !important;
    }
     
    .feedIdentifierCreator p:nth-child(2) {
     
        font-size: .6rem !important;
        
    }
    
 }

 @media screen and (max-width: 375px) {

    .feedIdentifierCreator p:nth-child(1) {
        margin-top: 0.8rem;
        font-weight: bold;
        font-size: 1rem !important;
    }
     
    .feedIdentifierCreator p:nth-child(2) {
     
        font-size: .6rem !important;
        
    }
    
 }