.feedPostVideo .container{
    text-align: center;
    display: flex;
    align-items: center;
   justify-content: center; 
   margin-bottom: 2rem;
}

 

.feedPostVideo .reactplayer{
    /*margin-bottom: 1rem; */ 
    /*border-radius: 15px;*/
    max-width: 95%;
   /* align-items: center;*/
   /* justify-content: center;*/
    /*border: 2px solid #ff6bd5;*/
    object-fit: cover;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 1px #cecece;
   
}

