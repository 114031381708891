  .feedNewComment {
     
    width: 33rem;
    margin-left: 10rem;
    margin-bottom: 1.5rem;
   
  }



  .feedNewComment p {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    
  }

  .feedNewComment .card {
     background-color: rgb(246, 247, 247);
     margin: 1rem;  
     box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px,
       rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 
        0px 12px 13px,
         rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
  }

  .feedNewComment textarea {
    width: 95%;
    resize: none;
    height: 10rem;
    padding:0.5rem;
    outline: none;
    font-size: 1rem;
    background-color: white;
    border: 1px solid #800080;
    border-radius: 8px;
    font-family: Helvetica, sans-serif;
  }


  .feedNewComment form {
     display: flex;   
     justify-content: center;
     flex-direction: column;
     align-items: center;
  }


  .feedNewComment form label {
    color: black;
    font-size: .9rem;
    font-weight: bold;
    margin: 0.8rem;
    font-family: Helvetica, sans-serif;

  }


 .feedNewComment form button {
      font-size: 1.2rem;
      padding: 0.5rem;
      margin: 0.5rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      background-color: #800080;
      border: none;
      font-family: Helvetica, sans-serif;
      color: white;
   }

  .feedNewComment form button:hover{
    background-color: #800080;
    border: none;
  }

   


   #messageEmpty {
    background-color: rgb(223, 60, 60);
    border-radius: 10px;
    padding: 0.5rem 0.5rem;
    font-size: 1rem !important;
    margin-bottom: 1rem !important;
  }

  #red{
    background-color: rgb(223, 60, 60);
  }



  /***************RESPONSIVE**********************/
  @media(max-width:885px) {
    .feedNewComment{
        margin-left: 1rem;
    }
  }

  @media(max-width:585px) {
    .feedNewComment {
      margin-left: 8rem;
      max-width: 420px ;
    }
 }
 
  @media screen and (max-width:440px){
    .feedNewComment{
      width: 320px ;
      max-width: 320px ;
      margin: 0%;
       
    }
  
    .feedNewComment textarea {
      width: 95%;
      resize: none;
      height: 7rem;
      font-size: .8rem;
      
    }
  
    .feedNewComment form label {
      
      font-size: 1rem;
      
      }
  
    .feedNewComment form button {
      font-size: 1rem;
      padding: 0.5rem;
      
   }
   .feedNewComment .card {
      width: 100%;   
      margin: 1.5rem !important;  
      margin-left: 1.5rem !important;
     
  }
 }

 
 @media screen and (max-width:400px) {
    .feedNewComment{
      width: 310px ;
       
       
    }
  
    .feedNewComment textarea {
      width: 95%;
      resize: none;
      height: 7rem;
      font-size: .9rem;
      
    }
  
    .feedNewComment form label {
      
      font-size: 1rem;
      
      }
  
    .feedNewComment form button {
      font-size: 1rem;
      padding: 0.5rem;
      
   }
   .feedNewComment .card {
    width: 100%;
    margin: 1.5rem;  
    margin-left: -8rem;
   
  } 
  }
 
  
 @media screen and (max-width:380px) {
     
  

  .feedNewComment {
    width: 100%;
    margin-left: 10px;
    
   
  } 

  .feedNewComment .card {
    max-width: 15rem;
  }

  .feedNewComment textarea {
    width: 95%;
    resize: none;
    height: 7rem;
    font-size: .8rem;
    
  }

  .feedNewComment form label {
    
    font-size: .9rem;
    
    }

  .feedNewComment form button {
    font-size: 1rem;
    padding: 0.5rem;
    
 }
 .feedNewComment .card {
  
  margin: 1.5rem;  
  margin-left: 3rem;
 
}

}


 @media screen and (max-width:320px) {
     
    .feedNewComment{
      width: 300px;
      max-width: 300px;
       
    }

    .feedNewComment textarea {
      width: 95%;
      resize: none;
      height: 7rem;
      font-size: .7rem;
      
    }

    .feedNewComment form label {
      
      font-size: .8rem;
      
      }

    .feedNewComment form button {
      font-size: .9rem;
      padding: 0.5rem;
      
   }
   .feedNewComment .card {
    
    margin: 1.5rem;  
   
   
 }

}