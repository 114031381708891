  .logoToAllPostsOfOneUser img{
    position: absolute !important;
    height: 3rem;
    width: 3rem;
    margin: 1rem;
    left: 77rem ;
    margin-top: -3rem;
    object-fit: cover;
    border-radius: 50%;
    /*box-shadow: 0 0 0 1px #cecece;*/
 
}


/*****************RESPONSIVE****************************/

@media screen and (max-width: 3900px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 118rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 3210px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 117rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 3180px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 113rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 3110px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 112rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 3020px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 110rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 2960px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 108rem;
        margin-top: -2.6rem;
        
    }
    
    
 } 

 @media screen and (max-width: 2915px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 107rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 2845px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 104rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 2800px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 101rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


@media screen and (max-width: 2730px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 100rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


@media screen and (max-width: 2620px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 99rem;
        margin-top: -2.6rem;
        
    }
    
    
 }



@media screen and (max-width: 2610px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 98rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

@media screen and (max-width: 2600px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 95rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 2500px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 94rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 2450px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 91rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 2340px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 89rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 2300px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 87rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


@media screen and (max-width: 2210px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 84rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

@media screen and (max-width: 2110px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 82rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 2050px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 78rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


@media screen and (max-width: 2000px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 77rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 1900px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 74rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 1795px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 72rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 1750px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 69rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


@media screen and (max-width: 1680px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 68rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 1610px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 67rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


@media screen and (max-width: 1580px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 66rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 1545px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 65rem;
        margin-top: -2.6rem;
        
    }}
    
    
 

@media screen and (max-width: 1520px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
       left: 63rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 1445px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
      left: 62rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 1432px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
      left: 62rem;
        margin-top: -2.6rem;
        
    }
    
    
 }



 @media screen and (max-width: 1420px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
       left: 60rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 1345px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
       left: 56rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 1280px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
       left: 56rem;
        margin-top: -2.6rem;
        
    }
    
    
 }



 @media screen and (max-width: 1270px) {

    .logoToAllPostsOfOneUser img{
        height: 2.6rem;
        width: 2.6rem;
        left: 56rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 
 @media screen and (max-width: 1255px) {

    .logoToAllPostsOfOneUser img{
        height: 2.5rem;
        width: 2.5rem;
        left: 55rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

@media screen and (max-width: 1215px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
        left: 53rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 1115px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
      left: 52rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


@media screen and (max-width: 1110px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
        left: 49rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 1002px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
        left: 48rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 985px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
        left: 47rem;
        margin-top: -2.6rem;
        
    }
    
    
 }
 
 @media screen and (max-width: 935px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
        left: 45rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 898px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
        left: 42rem;
        margin-top: -2.6rem;
        
    }
    
    
 }
 
 

@media screen and (max-width: 780px) {

    .logoToAllPostsOfOneUser img{
        height: 2.4rem;
        width: 2.4rem;
        left: 39rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 735px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 37rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 695px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 37rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 675px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 35rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 648px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 34rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 640px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 33rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 615px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 31rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 605px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 28.5rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 590px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 30rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 565px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 28rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 550px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 28rem;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 535px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 27rem;
        margin-top: -2.6rem;
        
    }
    
    
 }
 
 @media screen and (max-width: 520px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 27rem;
        margin-top: -2.6rem;
        
    }
    
    
 }
 
 @media screen and (max-width: 510px) {

    .logoToAllPostsOfOneUser img{
        height: 2.3rem;
        width: 2.3rem;
        left: 25rem;
        margin-top: -2.6rem;
        
    }
    
    
 }
 

 @media screen and (max-width: 495px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 25rem;
        margin-top: -2.6rem;
        
    }
    
    
 }



 @media screen and (max-width: 477px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 24rem;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 460px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 23rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 440px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 22rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 430px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 22rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 420px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 21rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 405px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 20rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }

 @media screen and (max-width: 397px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 19rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }


 
 @media screen and (max-width: 385px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 18rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }


 @media screen and (max-width: 358px) {

    .logoToAllPostsOfOneUser img{
        height: 2.2rem;
        width: 2.2rem;
        left: 17rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }



 @media screen and (max-width: 345px) {

    .logoToAllPostsOfOneUser img{
        height: 2rem !important;
        width: 2rem;
        left: 15.5rem !important;
        margin-top: -2.6rem;
        
    }
    
    
 }
