
 
 h3{
    color: black !important;
    margin-left: 25%;
    font-size: 1.5rem !important;
    margin-bottom: 3rem;
    margin-top: 3rem;
    font-family: "Roboto";
    font-weight:300;
}

 

.resultsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
 }

 .feedDisplayPost h2{
     color: black; 
     font-size: 1.2rem;  
     font-family: Helvetica, sans-serif; 
     font-weight: 700;
     
 }

 .container {
  /**c'est là où il faut mettre les options de navigation sur les côtes
  /* display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;*/
    height: inherit;

    }

   .card {
    background-color: white;
    width: 100%;
    margin: 1rem 0;
    width: 20rem;
    max-width: 43rem;
    max-height: 145rem;
    
 }

 .card p {
    font-size: 2rem;
    padding: 1rem;
    margin-left: 1rem;
    color:black;
 }

  

 .photoMessage {
    display: flex; 
   
 }


   

   

  
 /*************RESPONSIVE********/

  
 
  
 @media screen and (max-width: 700px) { 
   .card{ 
    max-width: 45rem; 
    
  }
 }

  @media screen and (max-width: 675px) {
     
   .card{
     
     max-width: 37rem ;
     
   }
 
  
 }
 
 @media screen and (max-width: 475px) {
  h3{
    
    font-size: 1.2rem !important; 
     
    
}
    .card{
     
     max-width: 25rem;
     
   }
 
  
 }

 @media screen and (max-width: 390px) {
   h3{
    
    font-size: 1rem !important; 
     
    
}

  .card{
   
   max-width: 24rem;
   
 }


}


@media screen and (max-width: 330px) {
     
   .card{
     
    max-width: 23rem;
    
  }

 
}



