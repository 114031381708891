.feedPosts{
    flex-grow: 1;
    word-break: break-word;
    display: flex;
     
}

.feedPosts textarea{
    resize: none;
    width: 95%;
    height: 15rem;
    font-size: 1.3rem;
    overflow: hidden;
     
}

.feedPosts .post {  
     font-size: 1.2rem !important;
     text-align: left;
     justify-content: start;
     font-family: "Roboto", Helvetica, sans-serif;
     font-weight: 500;
     font-style: normal;
     margin-left: -110px !important;
     margin-top: 25px;
     overflow: hidden;
     color:black !important;
 }

/******RESPONSIVE*********/


      @media screen and (max-width:750px) {
        .feedPosts{
            max-width: 450px;
             
        }
        .feedPosts .post {  
          font-size: 1rem !important;
           margin-left: -110px !important;
        }
         
      }
      
      @media screen and (max-width:1024px) {
        
      }
      